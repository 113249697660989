<template>
    <!-- MODAL REGISTER -->
    <div class="modal modal-default fade in" tabindex="-1" role="dialog" id="modal-register">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <button class="modal-btn-close" @click="$root.closeModal('modal-register')">
                    X
                </button>
                <div class="row">
                    <div class="col-12">
                        <div class="modal-header">
                            <h2 class="modal-title">Novo registo</h2>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="modal-body-itens">
                        <form ref="formRegister" @submit.prevent="onRegister" class="modal-body-itens-form">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Nome</label>
                                        <input type="text" value="" name="name" placeholder="" required/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Código de registo</label>
                                        <input type="text" value="" name="code" placeholder=""/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Telemóvel</label>
                                        <input type="text" value="" name="phone" placeholder="" required/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>E-mail</label>
                                        <input type="email" value="" name="email" placeholder="" required/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Password</label>
                                        <input type="password" value="" name="password" placeholder="" required/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="content-input">
                                        <label>Repetir Password</label>
                                        <input type="password" value="" name="repet_password" placeholder="" required/>
                                    </div>
                                </div>
                                <!-- hidden input owner_id 4 == MUST -->
                                <input type="hidden" value="4" name="owner_id" />
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="content-input-check">
                                        <input type="checkbox" value="1" name="accepted_terms" required/>
                                        <label>Declaro que li e aceito os <a href="">Termos e Condições de Utilização</a>  e que li e compreendi a <a href="">Política de Privacidade.</a></label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="content-input-check">
                                        <input type="checkbox" value="1" name="accepted_communication" required/>
                                        <label>Permito a utilização dos dados para comunicação e campanhas</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-body-itens-form-btns">
                                        <button type="submit">Criar registo</button>
                                        <button class="btn-cancel" @click="$root.closeModal('modal-register')">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-------------------->
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';

export default {
    methods:{
        ...mapActions('App', ['loading']),        
        async onRegister(el){            
            let formData = new FormData(this.$refs.formRegister)

            this.loading(true);
            await axios({
                method: 'POST',
                url: '/api/v1/campaign/user/register',
                data: formData
            }).then(response => response.data)
            .then((data) => {                
                this.loading(false);
                this.$notify({                    
                    type: "success",
                    text: data.message,
                    duration: 10000
                });
                this.$root.closeModal("modal-register")
            })
            .catch((error) => {
                this.loading(false);
                this.$notify({                    
                    type: "warn",
                    text: error.response.data.message,
                    duration: 10000
                });
            })
        }
    }
}
</script>
<style>
.content-input-check{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.content-input-check input[type="checkbox"] {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 5px;
    float: left;
    border: 2px solid #E0E0E0;
    appearance: none; 
    outline: none; 
    display: inline-block;
}


.content-input-check input[type="checkbox"]:checked {
    background-color: #ffffff;
}

.content-input-check input[type="checkbox"]:checked::before {
    content: '\2713'; 
    display: inline-block;
    font-size: 25px;
    color: #5DAA8A;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: auto;
}

.content-input-check label {
    width: calc(100% - 40px);
    font-size: 18px;
    color: #000;
    font-weight: 400;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 0px;
    display: inline-block;
}
.content-input-check label a{
    color: #000;
    text-decoration: underline !important;;
}
.btn-cancel{
    all: unset;
    width: 100px!important;
    height: 50px;
    background-color: #fff!important;
    color: #EE8282!important;
    border: 1px solid #EE8282!important;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>